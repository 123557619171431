import React from 'react';


// components

import SectionHeading from '../components/SectionHeading';
import AppFeatureSingle from '../components/AppFeatureSingle';


import BackgroundAnimation from '../components/BackgroundAnimation';


// images
import video_img from '../../assets/images/business.jpg';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

import one from "../../assets/images/patterns/one.jpg";
import why from '../../assets/images/why.png';
import Team from '../../assets/images/team.png';


// data

import features from '../data/features';

import Whyus from '../components/Whyus';

const Index = () => {

    

    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1" >
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content c-white" style={{marginTop:'20%'}}>
                                    <h2>Synchronizing strategy and talent to drive superior performance.</h2>
                                    <p className="large"> At Acorn, we firmly believe that what&#39;s good for your business is good for ours. As an out-
staffing company, our success is inextricably linked to the success of our clients. That&#39;s why we work tirelessly to ensure that our services are aligned with your business goals and needs.                          
                                       .</p>
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="background-pattern background-pattern-1 "style={{ backgroundImage: `url(${one})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', height: '100%' }}>
                        
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    heading='Services we deliver'
                                    subHeading='Preparing for your success. we provide truly prominent IT solutions.'
                                    icon='las la-cog'
                                    additionalClasses='center width-84'
                                />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5" >
                            { features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                          
                                        />
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}

            {/* video - start */}
            <div className="video-section">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                                <div className="video-section-content">
                                    <div className="section-heading section-heading-1 center-responsive c-white">
                                        <div className="sub-heading upper ls-1">
                                       
                                        </div>
                                        <div className="main-heading">
                                            <h1> CRAFTING YOUR SUCCESS STORY </h1>
                                            <p> Finding the right talent can be a challenge. We offer a comprehensive talent acquisition service that includes sourcing, recruiting, and onboarding skilled professionals who are the perfect fit for your organization.
Whether you need one person or an entire project team, we can match you with skilled talent in technology, finance and accounting, administrative and customer support, legal, and marketing.
Our recruiting specialists can quickly fulfil the talent you need to propel your business forward.</p>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div className="video-section-video">
                                    <figure>
                                        <img className="drop-shadow-1" src={video_img} width='471' height='472' alt="drop-shadow" />
                                        
                                        
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* video - end */}
            <div className='whyus' style={{ display: 'flex', justifyContent: 'center' }}>
  <img
    className=""
    src={Team}
    height=''
    alt="drop-shadow"
    style={{ maxWidth: '100%', height: 'auto' }}
  />
</div>

       
                
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
  <h3 >Why Choose Us</h3>
  <img
    className=""
    src={why}
    alt="drop-shadow"
    style={{ maxWidth: '100%', height: 'auto' }}
  />
</div>



      
            {/* feature section - end */}

            {/* feature section - start */}
           
            {/* feature section - end */}

            {/* pricing section - start */}
         
            {/* pricing section - end */}

            {/* testimonial section - start 
            <div className="testimonial-section">
                <div className="testimonial-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-comments'
                                    heading='feedbacks'
                                    subHeading='What people are talking about.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <TestimonialSlider/>
                        </div>
                    </div>
                </div>
            </div>
            testimonial section - end */}
            <div className='whyus' style={{}}> <Whyus/> </div>
            

            {/* faq section - start */}
            
            {/* faq section - end */}

            {/* screen section - start */}
          
            {/* screem section - end */}

            {/* blog section - start */}
           
            {/* blog section - end */}

            {/* cta section - start */}
        
            {/* cta section - end */}

            {/* contact form section - start */}
            <div className="contact-form-section contact-form-section-1">
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* contact form - start 
                                <ContactForm1/>
                                {/* contact form - end */}
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* newsletter form - start 
                                <ContactForm2/>
                                {/* newsletter form - end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact form section - end */}
        </>
    );
};

export default Index;
