import React from 'react';

import PageHeader from '../components/PageHeader';
import TeamSlider from '../components/TeamSlider';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';
import SectionHeading from '../components/SectionHeading';
import TestimonialSlider from '../components/TestimonialSlider';
import BackgroundAnimation from '../components/BackgroundAnimation';
import faq from '../data/faq';

// images
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import about_image from '../../assets/images/power.jpg';
import about_smt from '../../assets/images/buil.jpg';
import AppFeatureSingle from '../components/AppFeatureSingle';

const About = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='About Us'
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: 'About',
                        to: '/about'
                    }
                ]}
            />
            {/* page header - end */}

            {/* about section - start */}
            <div className="about-section">
                <div className="about-section-wrapper">
                    <div className="container">
                        {/* first half - start */}
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="about-section-content c-grey">
                                    <div className="section-heading">
                                        <div className="sub-heading c-blue upper ls-1">
                                            <i className="las"></i>
                                            <h5>introduction</h5>
                                        </div>
                                        <div className="main-heading c-dark">
                                            <h3>Unleash the power of synergy and triumph together.</h3>
                                        </div>
                                    </div>
                                    <p className="paragraph-medium">Acorn is a fast growing out-staffing solutions company. Since 2022, Acorn has been catering to the diverse needs of global clients and constantly striving to add value to clients’ businesses.
At Acorn, we are dedicated to being a true extension of your internal team. We are here to support you with your plans for growth, equip you with the latest technology, suggest holistic solutions, and deliver exceptional customer service.
Our approach involves utilizing established staffing models that are tailored to your specific needs. We ensure that you experience accelerated speed-to-market, meet customer expectations, increase revenue, and easily adapt to changing requirements.
Unlock the true potential of your business with Acorn, the premier provider of comprehensive out-staffing solutions. 
Unleash your business potential, enhance your efficiency, and gain a competitive edge in the market. Contact us today to explore how our tailored out-staffing solutions can revolutionize your organization. Together, let's create a future of unparalleled success.
.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                                <div className="about-section-image">
                                    <div className="pattern-image pattern-image-1">
                                        <div className="pattern-image-wrapper">
                                            <img className="drop-shadow-1" src={about_image} height='450' alt="pattern" />
                                            <div className="background-pattern background-pattern-radius drop-shadow-1">
                                                <BackgroundAnimation/>
                                                <div className="background-pattern-gradient"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* first half - end */}


                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="about-section-content c-grey">
                                    <div className="section-heading" style={{marginTop:'10%'}}>
                                        
                                        <div className="main-heading c-dark">
                                            <h3>Let's build something together</h3>
                                        </div>
                                    </div>
                                    <p className="paragraph-medium">Our fundamental belief is that the workforce we provide to our clients should integrate seamlessly with their existing operations. Thus, we work closely with you to prioritize your culture and brand in your staffing solution, ensuring that we deliver a high-performing team that aligns perfectly with your vision, mission, and culture. 
.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                                <div  className="about-section-image">
                                    <div className="pattern-image pattern-image-1">
                                        <div className="pattern-image-wrapper">
                                            <img  className="drop-shadow-1" src={about_smt} height='350' alt="pattern" />
                                            <div className="background-pattern background-pattern-radius drop-shadow-1">
                                                <BackgroundAnimation/>
                                                <div className="background-pattern-gradient"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* second half - start */}
                        
                        </div>
                        {/* second half - end */}
                    </div>
                </div>
    
            {/* about section - end */}

            {/* team section - start */}
            <div className="team">
                <div className="team-wrapper">
                    <div className="team-inner">
                        <div className="container">
                            {/* team section heading - start */}
                            <div className="row">
                                <div className="col">
                                    <div className="section-heading center c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las "></i>
                                           
                                        </div>
                                        <div className="main-heading">
                                            <h1>Our Vission and Mission</h1>
                                     
                                            
                                        </div>
                                        <div  className="container team-slider-container" style={{width:"", display:"flex", justifyContent:'',justifyItems:'', flexDirection:'column',alignItems:"center"}}> 
                                    <h3 style={{width:"", display:"flex", justifyContent:'start',justifyItems:'start'}}> Vission:</h3>

                                   <h3 style={{width:"50%", display:"flex", justifyContent:'',justifyItems:''}}>
                                       We want to be a dominant and a disruptive force in the tech space. 
                                     </h3>
                                 </div>
                                 <div style={{width:"", display:"flex", justifyContent:'',justifyItems:'', flexDirection:'column',marginTop:'4%',alignItems:"center",marginBottom:"10%"}}> 
                                    <h3 style={{width:"", display:"flex", justifyContent:'start',justifyItems:'start'}}> Mission:</h3>

                                   <h3 style={{width:"50%", display:"flex", justifyContent:'',justifyItems:'',marginBottom:"10%"}}>
                                   We empower businesses to leverage the power of technology and enjoy unprecedented growth.  
                                     </h3>
                                 </div>


                                 
                                    </div>
                                </div>
                            </div>
                            {/* team section heading - end */}
                        </div>
                        <div className="container team-slider-container">
                            {/* team slider - start */}
                            <div className="row">
                                <div className="col">
                                    
                                   
                                  <div style={{width:"", display:"flex", justifyContent:'',justifyItems:'', flexDirection:'column',marginTop:'10%',color:"white"}}> 
                                    <h> </h>                                 </div>
                                 
                             </div>
                                
                                
                         </div>
                            {/* team slider - end */}
                        </div>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* team section - end */}
            

           

            {/* faq section - start */}
            <div className="faq-section faq-section-1">
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="">
                                <h3 style={{alignContent:'center', justifyContent:'center', alignItems:"center", color:'darkblue'}}> What we Beleive</h3>
                                <div> <p className="paragraph-medium">We are obsessed about our client’s success. We breathe life into entrepreneurial ideas, infuse creativity and innovation into our solutions and unleash disruption by changing the status quo.
We value our team and understand that a company, in essence, is only as good as its people. We encourage and support our people to take up ownership of their tasks and contribute to it in ways they see value in. We encourage continuous learning and will support employees to expand their skills and areas of interest.
</p></div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
            {/* faq section - end */}

          
        </>
    );
};

export default About;
