import React from 'react';
import hr2 from '../../assets/images/hw.png';
import hr3 from '../../assets/images/team.png';
import hr4 from '../../assets/images/interview.png';
import hr5 from '../../assets/images/rec.png';
import hr6 from '../../assets/images/pro.png';

function Whyus() {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', animation: 'float 3s ease-in-out infinite', marginLeft: ''}}>
        <h3>How we Work</h3>
      </div>
      <div className="row" style={{ justifyContent: 'center',marginTop:'4%', }}>
      <div className="col-sm-2" style={{ textAlign: 'center', border: '3px solid #0000', padding: '3%', borderRadius: '30px', marginRight:'2%' , maxWidth: '100%', height: 'auto',backgroundColor:'#eff6ff'}}>
  <h4>Requirement Gathering</h4>
  <img src={hr2} alt="" style={{ display: 'block', margin: '0 auto', width: 'auto', maxWidth: '100%', height: '40%' }}/>
  <p>Understand the key requirements and job specifications</p>
</div>


        <div className="col-sm-2" style={{ textAlign: 'center',border: '', padding: '3%', borderRadius: '30px', marginRight:'2%', maxWidth: '100%', height: 'auto',backgroundColor:'#eff6ff'  }}>
          <h4>Team Selection</h4>
          <img src={hr3} alt="" style={{ display: 'block', margin: '0 auto', width: 'auto', maxWidth: '100%', height: '35%',margin:'12%' }}/>
          <p>Identify and shortlist the ideal potential candidates</p>
        </div>

        <div className="col-sm-2" style={{ textAlign: 'center',border: '',padding: '3%', borderRadius: '30px', marginRight:'2%',  maxWidth: '100%', height: 'auto' ,backgroundColor:'#eff6ff'}}>
          <h4>Interview</h4>
          <img src={hr4} alt=""style={{ display: 'block', margin: '0 auto', width: 'auto', maxWidth: '100%', height: '40%',marginBottom:'12%'}} />
          <p>After Acorn's initial screening, clients can interview shortlisted candidates.</p>
        </div>

        <div className="col-sm-2" style={{ textAlign: 'center',border: '',padding: '3%', borderRadius: '30px', marginRight:'2%' ,  maxWidth: '100%', height: 'auto' ,backgroundColor:'#eff6ff'}}>
          <h4>Recruiting</h4>
          <img src={hr5} alt="" style={{ display: 'block', margin: '0 auto', width: 'auto', maxWidth: '100%', height: '40%', marginBottom:'12%' }}/>
          <p>Acorn manages onboarding of selected candidates</p>
        </div>

        <div className="col-sm-2" style={{ textAlign: 'center', border: '', padding: '3%', borderRadius: '30px',   maxWidth: '100%', height: 'auto',backgroundColor:'#eff6ff'}}>
  <h4>Project Kick-off</h4>
  <img src={hr6} alt="" style={{ display: 'block', margin: '0 auto', width: 'auto', maxWidth: '100%', height: '40%' }} />
  <p>After completing the necessary formalities, clients can kickstart the project</p>
</div>

      </div>
    </div>
  );
}

export default Whyus;
