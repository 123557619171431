import hr from '../../assets/images/hr.png';
import two from '../../assets/images/two.svg';
import hr2 from '../../assets/images/hr2.png'
import hr3 from '../../assets/images/hr3.png'
import hr4 from '../../assets/images/hr4.png';
import hr5 from '../../assets/images/hr5.png';
import hr6 from '../../assets/images/hr6.png';
const features = [
    {
        icon: hr,
        heading: 'Talent Acquisition',
        excerpt: 'Acquire best-in-class talent to gain your competitive advantage',
        to: '/feature-detail-1'
    },
    {
        icon: hr2,
        heading: 'HR Outsourcing',
        excerpt: 'Unlock cost savings, efficiency, and expertise with HR outsourcing.',
        to: '/feature-detail-1'
    },
    {
        icon: hr3,
        heading: 'Business Support Services',
        excerpt: 'Helping to drive growth through focus on core business..',
        to: '/feature-detail-1'
    },
    {
        icon: hr4,
        heading: 'HR Consultancy Services',
        excerpt: 'Elevate your HR strategy with expert guidance and solutions through HR consultancy services.',
        to: '/feature-detail-1'
    },
    {
        icon: hr5,
        heading: 'Background Verification Services',
        excerpt: 'Accurate background verification checks when you need it.',
        to: '/feature-detail-1'
    },
   
    {
        icon: hr6,
        heading: 'Resource Augmentation Services',
        excerpt: 'Access specialized skills, and optimize workforce flexibility..',
        to: '/feature-detail-1'
    }
];

export default features;