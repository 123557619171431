import React from 'react';

import { Link } from 'react-router-dom';

// images
import logo from '../../assets/images/logo.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const Footer = () => {
    return (
        <footer className="footer" style={{backgroundColor:'honeydew'}}>
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-row">
                                <div className="footer-detail">
                                    <Link to="#">
                                        <img src={logo} alt="footer-logo" />
                                    </Link>
                                    <p className="c-grey-1">Acorn Solution for your technical support</p>
                                    <div className="links">
                                        <a className="link-underline" href="mailto:hello@acornsolution.net">
                                            <span>hello@acornsolution.net</span>
                                        </a>
                                        <a className="link-underline" href="tel:+1-234-567-890">
                                            <span>+94 70 370330</span>
                                        </a>
                                        <a className="link-underline" href="tel:+1-234-567-890">
                                            <span>Jezima Complex , 436-440

                                                            Galle Road , <br></br>Colombo 3, Sri Lanka</span>
                                        </a>

                                        <li className="linkedin">
                                            <Link to="https://www.linkedin.com/in/s-praga/" className="link-underline">
                                                <i className="fab fa-linkedin-in"></i>
                                                <span>Linkedin</span>
                                            </Link>
                                        </li>
                                    </div>
                                </div>
                                <div className="footer-list footer-social social-gradient">
                                    
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-copyright c-grey-1">
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-pattern" style={{backgroundImage: `url(${''})`, backgroundColor:'honeydew'}}></div>
            </div>
        </footer>
    );
};

export default Footer;
